import { default as activate2uC0YDmucNMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/activate.vue?macro=true";
import { default as indexvySY8YVf0aMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/index.vue?macro=true";
import { default as restore7u853g5YX9Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/organisations/restore.vue?macro=true";
import { default as register_45complete7tmemr0Wi4Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/register-complete.vue?macro=true";
import { default as register9R8Y8xEcKgMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/register.vue?macro=true";
import { default as _91token_93TVr9IcSkIoMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/boards/[token].vue?macro=true";
import { default as indexn6CKxdPT0lMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93Sd7EHdTjYDMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/dashboards/[id].vue?macro=true";
import { default as indexuM88MmkPLTMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93ghtWqSzC3BMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/dashboards/token/[token].vue?macro=true";
import { default as tokenqJP6XMud7IMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/dashboards/token.vue?macro=true";
import { default as demon9OzanxnTTMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/demo.vue?macro=true";
import { default as indexjYHYM5x2j3Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/events/index.vue?macro=true";
import { default as impressum7gJyHYek2KMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/impressum.vue?macro=true";
import { default as indexekRFwybdioMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/index.vue?macro=true";
import { default as _91id_93IhMouqg1AbMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/leaderboards/[id].vue?macro=true";
import { default as indexKuzsdUMCQNMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/leaderboards/index.vue?macro=true";
import { default as _91id_93J8sbngOGAnMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexTFWIHiRF72Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/deals/index.vue?macro=true";
import { default as HSLtoRGBjHtBKNIMvVMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as RGBtoHSLb1Nx2eshx3Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93Gss1lagTwUMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93wUtjhL2RWgMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as indexoq3uU8JS2lMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/index.vue?macro=true";
import { default as _91id_93nTbDe7BqG8Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as indexjMxC1Lg4t9Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_93pP6QnrRIyoMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexfPCkyhn1hUMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/goals/index.vue?macro=true";
import { default as indexsiImEwZkCZMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/settings/index.vue?macro=true";
import { default as indexx5AB4RWHi6Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_93HJHsQMGvfFMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexv2PTWqGJlYMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93VyuKVSPoV8Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/sounds/[id].vue?macro=true";
import { default as entriesaCE4WgXWe1Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlistsE9gSSlinTaMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexUvihMxLSG6Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/sounds/index.vue?macro=true";
import { default as indexaiStXlDkLJMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_93bc672Uxe1WMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexoFkpYAQaFVMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93FUMFVT0kC6Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexmLuwtaSXP8Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/hardware/index.vue?macro=true";
import { default as indexXCfY1mprIcMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_93pMBjBdRUsMMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexIGAWege6eJMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_93p7AzuzoOZGMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexs3m2EONyrkMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93ecKMBZhHalMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/plans/[id].vue?macro=true";
import { default as indexeq2zNaNdtIMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/plans/index.vue?macro=true";
import { default as releaselogfqBGPZigofMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/releaselog.vue?macro=true";
import { default as tools1Y6GaeagjbMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/tools.vue?macro=true";
import { default as _91id_93n4mnRuzDI4Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/users/[id].vue?macro=true";
import { default as indexVLV14RAg35Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: activate2uC0YDmucNMeta?.name ?? "auth-activate",
    path: activate2uC0YDmucNMeta?.path ?? "/auth/activate",
    meta: activate2uC0YDmucNMeta || {},
    alias: activate2uC0YDmucNMeta?.alias || [],
    redirect: activate2uC0YDmucNMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: indexvySY8YVf0aMeta?.name ?? "auth",
    path: indexvySY8YVf0aMeta?.path ?? "/auth",
    meta: indexvySY8YVf0aMeta || {},
    alias: indexvySY8YVf0aMeta?.alias || [],
    redirect: indexvySY8YVf0aMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: restore7u853g5YX9Meta?.name ?? "auth-organisations-restore",
    path: restore7u853g5YX9Meta?.path ?? "/auth/organisations/restore",
    meta: restore7u853g5YX9Meta || {},
    alias: restore7u853g5YX9Meta?.alias || [],
    redirect: restore7u853g5YX9Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/organisations/restore.vue").then(m => m.default || m)
  },
  {
    name: register_45complete7tmemr0Wi4Meta?.name ?? "auth-register-complete",
    path: register_45complete7tmemr0Wi4Meta?.path ?? "/auth/register-complete",
    meta: register_45complete7tmemr0Wi4Meta || {},
    alias: register_45complete7tmemr0Wi4Meta?.alias || [],
    redirect: register_45complete7tmemr0Wi4Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/register-complete.vue").then(m => m.default || m)
  },
  {
    name: register9R8Y8xEcKgMeta?.name ?? "auth-register",
    path: register9R8Y8xEcKgMeta?.path ?? "/auth/register",
    meta: register9R8Y8xEcKgMeta || {},
    alias: register9R8Y8xEcKgMeta?.alias || [],
    redirect: register9R8Y8xEcKgMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91token_93TVr9IcSkIoMeta?.name ?? "boards-token",
    path: _91token_93TVr9IcSkIoMeta?.path ?? "/boards/:token()",
    meta: _91token_93TVr9IcSkIoMeta || {},
    alias: _91token_93TVr9IcSkIoMeta?.alias || [],
    redirect: _91token_93TVr9IcSkIoMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/boards/[token].vue").then(m => m.default || m)
  },
  {
    name: indexn6CKxdPT0lMeta?.name ?? "buzzer",
    path: indexn6CKxdPT0lMeta?.path ?? "/buzzer",
    meta: indexn6CKxdPT0lMeta || {},
    alias: indexn6CKxdPT0lMeta?.alias || [],
    redirect: indexn6CKxdPT0lMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/buzzer/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Sd7EHdTjYDMeta?.name ?? "dashboards-id",
    path: _91id_93Sd7EHdTjYDMeta?.path ?? "/dashboards/:id()",
    meta: _91id_93Sd7EHdTjYDMeta || {},
    alias: _91id_93Sd7EHdTjYDMeta?.alias || [],
    redirect: _91id_93Sd7EHdTjYDMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/dashboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexuM88MmkPLTMeta?.name ?? "dashboards",
    path: indexuM88MmkPLTMeta?.path ?? "/dashboards",
    meta: indexuM88MmkPLTMeta || {},
    alias: indexuM88MmkPLTMeta?.alias || [],
    redirect: indexuM88MmkPLTMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: tokenqJP6XMud7IMeta?.name ?? "dashboards-token",
    path: tokenqJP6XMud7IMeta?.path ?? "/dashboards/token",
    children: [
  {
    name: _91token_93ghtWqSzC3BMeta?.name ?? "dashboards-token-token",
    path: _91token_93ghtWqSzC3BMeta?.path ?? ":token()",
    meta: _91token_93ghtWqSzC3BMeta || {},
    alias: _91token_93ghtWqSzC3BMeta?.alias || [],
    redirect: _91token_93ghtWqSzC3BMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/dashboards/token/[token].vue").then(m => m.default || m)
  }
],
    meta: tokenqJP6XMud7IMeta || {},
    alias: tokenqJP6XMud7IMeta?.alias || [],
    redirect: tokenqJP6XMud7IMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/dashboards/token.vue").then(m => m.default || m)
  },
  {
    name: demon9OzanxnTTMeta?.name ?? "demo",
    path: demon9OzanxnTTMeta?.path ?? "/demo",
    meta: demon9OzanxnTTMeta || {},
    alias: demon9OzanxnTTMeta?.alias || [],
    redirect: demon9OzanxnTTMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: indexjYHYM5x2j3Meta?.name ?? "events",
    path: indexjYHYM5x2j3Meta?.path ?? "/events",
    meta: indexjYHYM5x2j3Meta || {},
    alias: indexjYHYM5x2j3Meta?.alias || [],
    redirect: indexjYHYM5x2j3Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: impressum7gJyHYek2KMeta?.name ?? "impressum",
    path: impressum7gJyHYek2KMeta?.path ?? "/impressum",
    meta: impressum7gJyHYek2KMeta || {},
    alias: impressum7gJyHYek2KMeta?.alias || [],
    redirect: impressum7gJyHYek2KMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexekRFwybdioMeta?.name ?? "index",
    path: indexekRFwybdioMeta?.path ?? "/",
    meta: indexekRFwybdioMeta || {},
    alias: indexekRFwybdioMeta?.alias || [],
    redirect: indexekRFwybdioMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IhMouqg1AbMeta?.name ?? "leaderboards-id",
    path: _91id_93IhMouqg1AbMeta?.path ?? "/leaderboards/:id()",
    meta: _91id_93IhMouqg1AbMeta || {},
    alias: _91id_93IhMouqg1AbMeta?.alias || [],
    redirect: _91id_93IhMouqg1AbMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/leaderboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKuzsdUMCQNMeta?.name ?? "leaderboards",
    path: indexKuzsdUMCQNMeta?.path ?? "/leaderboards",
    meta: indexKuzsdUMCQNMeta || {},
    alias: indexKuzsdUMCQNMeta?.alias || [],
    redirect: indexKuzsdUMCQNMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/leaderboards/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93J8sbngOGAnMeta?.name ?? "organisation-deals-id",
    path: _91id_93J8sbngOGAnMeta?.path ?? "/organisation/deals/:id()",
    meta: _91id_93J8sbngOGAnMeta || {},
    alias: _91id_93J8sbngOGAnMeta?.alias || [],
    redirect: _91id_93J8sbngOGAnMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/deals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTFWIHiRF72Meta?.name ?? "organisation-deals",
    path: indexTFWIHiRF72Meta?.path ?? "/organisation/deals",
    meta: indexTFWIHiRF72Meta || {},
    alias: indexTFWIHiRF72Meta?.alias || [],
    redirect: indexTFWIHiRF72Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/deals/index.vue").then(m => m.default || m)
  },
  {
    name: HSLtoRGBjHtBKNIMvVMeta?.name ?? "organisation-devices-HSLtoRGB",
    path: HSLtoRGBjHtBKNIMvVMeta?.path ?? "/organisation/devices/HSLtoRGB",
    meta: HSLtoRGBjHtBKNIMvVMeta || {},
    alias: HSLtoRGBjHtBKNIMvVMeta?.alias || [],
    redirect: HSLtoRGBjHtBKNIMvVMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/HSLtoRGB.js").then(m => m.default || m)
  },
  {
    name: RGBtoHSLb1Nx2eshx3Meta?.name ?? "organisation-devices-RGBtoHSL",
    path: RGBtoHSLb1Nx2eshx3Meta?.path ?? "/organisation/devices/RGBtoHSL",
    meta: RGBtoHSLb1Nx2eshx3Meta || {},
    alias: RGBtoHSLb1Nx2eshx3Meta?.alias || [],
    redirect: RGBtoHSLb1Nx2eshx3Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/RGBtoHSL.js").then(m => m.default || m)
  },
  {
    name: _91id_93Gss1lagTwUMeta?.name ?? "organisation-devices-id",
    path: _91id_93Gss1lagTwUMeta?.path ?? "/organisation/devices/:id()",
    meta: _91id_93Gss1lagTwUMeta || {},
    alias: _91id_93Gss1lagTwUMeta?.alias || [],
    redirect: _91id_93Gss1lagTwUMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93wUtjhL2RWgMeta?.name ?? "organisation-devices-devicegroup-id",
    path: _91id_93wUtjhL2RWgMeta?.path ?? "/organisation/devices/devicegroup/:id()",
    meta: _91id_93wUtjhL2RWgMeta || {},
    alias: _91id_93wUtjhL2RWgMeta?.alias || [],
    redirect: _91id_93wUtjhL2RWgMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/devicegroup/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoq3uU8JS2lMeta?.name ?? "organisation-devices",
    path: indexoq3uU8JS2lMeta?.path ?? "/organisation/devices",
    meta: indexoq3uU8JS2lMeta || {},
    alias: indexoq3uU8JS2lMeta?.alias || [],
    redirect: indexoq3uU8JS2lMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nTbDe7BqG8Meta?.name ?? "organisation-eventTypes-id",
    path: _91id_93nTbDe7BqG8Meta?.path ?? "/organisation/eventTypes/:id()",
    meta: _91id_93nTbDe7BqG8Meta || {},
    alias: _91id_93nTbDe7BqG8Meta?.alias || [],
    redirect: _91id_93nTbDe7BqG8Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/eventTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjMxC1Lg4t9Meta?.name ?? "organisation-eventTypes",
    path: indexjMxC1Lg4t9Meta?.path ?? "/organisation/eventTypes",
    meta: indexjMxC1Lg4t9Meta || {},
    alias: indexjMxC1Lg4t9Meta?.alias || [],
    redirect: indexjMxC1Lg4t9Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/eventTypes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pP6QnrRIyoMeta?.name ?? "organisation-goals-id",
    path: _91id_93pP6QnrRIyoMeta?.path ?? "/organisation/goals/:id()",
    meta: _91id_93pP6QnrRIyoMeta || {},
    alias: _91id_93pP6QnrRIyoMeta?.alias || [],
    redirect: _91id_93pP6QnrRIyoMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/goals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfPCkyhn1hUMeta?.name ?? "organisation-goals",
    path: indexfPCkyhn1hUMeta?.path ?? "/organisation/goals",
    meta: indexfPCkyhn1hUMeta || {},
    alias: indexfPCkyhn1hUMeta?.alias || [],
    redirect: indexfPCkyhn1hUMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/goals/index.vue").then(m => m.default || m)
  },
  {
    name: indexsiImEwZkCZMeta?.name ?? "organisation-settings",
    path: indexsiImEwZkCZMeta?.path ?? "/organisation/settings",
    meta: indexsiImEwZkCZMeta || {},
    alias: indexsiImEwZkCZMeta?.alias || [],
    redirect: indexsiImEwZkCZMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexx5AB4RWHi6Meta?.name ?? "organisation-subscriptions",
    path: indexx5AB4RWHi6Meta?.path ?? "/organisation/subscriptions",
    meta: indexx5AB4RWHi6Meta || {},
    alias: indexx5AB4RWHi6Meta?.alias || [],
    redirect: indexx5AB4RWHi6Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HJHsQMGvfFMeta?.name ?? "organisation-users-id",
    path: _91id_93HJHsQMGvfFMeta?.path ?? "/organisation/users/:id()",
    meta: _91id_93HJHsQMGvfFMeta || {},
    alias: _91id_93HJHsQMGvfFMeta?.alias || [],
    redirect: _91id_93HJHsQMGvfFMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexv2PTWqGJlYMeta?.name ?? "organisation-users",
    path: indexv2PTWqGJlYMeta?.path ?? "/organisation/users",
    meta: indexv2PTWqGJlYMeta || {},
    alias: indexv2PTWqGJlYMeta?.alias || [],
    redirect: indexv2PTWqGJlYMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/organisation/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VyuKVSPoV8Meta?.name ?? "sounds-id",
    path: _91id_93VyuKVSPoV8Meta?.path ?? "/sounds/:id()",
    meta: _91id_93VyuKVSPoV8Meta || {},
    alias: _91id_93VyuKVSPoV8Meta?.alias || [],
    redirect: _91id_93VyuKVSPoV8Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/sounds/[id].vue").then(m => m.default || m)
  },
  {
    name: indexUvihMxLSG6Meta?.name ?? "sounds",
    path: indexUvihMxLSG6Meta?.path ?? "/sounds",
    children: [
  {
    name: entriesaCE4WgXWe1Meta?.name ?? "sounds-index-entries",
    path: entriesaCE4WgXWe1Meta?.path ?? "entries",
    meta: entriesaCE4WgXWe1Meta || {},
    alias: entriesaCE4WgXWe1Meta?.alias || [],
    redirect: entriesaCE4WgXWe1Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/sounds/index/entries.vue").then(m => m.default || m)
  },
  {
    name: playlistsE9gSSlinTaMeta?.name ?? "sounds-index-playlists",
    path: playlistsE9gSSlinTaMeta?.path ?? "playlists",
    meta: playlistsE9gSSlinTaMeta || {},
    alias: playlistsE9gSSlinTaMeta?.alias || [],
    redirect: playlistsE9gSSlinTaMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/sounds/index/playlists.vue").then(m => m.default || m)
  }
],
    meta: indexUvihMxLSG6Meta || {},
    alias: indexUvihMxLSG6Meta?.alias || [],
    redirect: indexUvihMxLSG6Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/sounds/index.vue").then(m => m.default || m)
  },
  {
    name: indexaiStXlDkLJMeta?.name ?? "system-devices",
    path: indexaiStXlDkLJMeta?.path ?? "/system/devices",
    meta: indexaiStXlDkLJMeta || {},
    alias: indexaiStXlDkLJMeta?.alias || [],
    redirect: indexaiStXlDkLJMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bc672Uxe1WMeta?.name ?? "system-firmware-id",
    path: _91id_93bc672Uxe1WMeta?.path ?? "/system/firmware/:id()",
    meta: _91id_93bc672Uxe1WMeta || {},
    alias: _91id_93bc672Uxe1WMeta?.alias || [],
    redirect: _91id_93bc672Uxe1WMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/firmware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoFkpYAQaFVMeta?.name ?? "system-firmware",
    path: indexoFkpYAQaFVMeta?.path ?? "/system/firmware",
    meta: indexoFkpYAQaFVMeta || {},
    alias: indexoFkpYAQaFVMeta?.alias || [],
    redirect: indexoFkpYAQaFVMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/firmware/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FUMFVT0kC6Meta?.name ?? "system-hardware-id",
    path: _91id_93FUMFVT0kC6Meta?.path ?? "/system/hardware/:id()",
    meta: _91id_93FUMFVT0kC6Meta || {},
    alias: _91id_93FUMFVT0kC6Meta?.alias || [],
    redirect: _91id_93FUMFVT0kC6Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/hardware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmLuwtaSXP8Meta?.name ?? "system-hardware",
    path: indexmLuwtaSXP8Meta?.path ?? "/system/hardware",
    meta: indexmLuwtaSXP8Meta || {},
    alias: indexmLuwtaSXP8Meta?.alias || [],
    redirect: indexmLuwtaSXP8Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexXCfY1mprIcMeta?.name ?? "system-logs",
    path: indexXCfY1mprIcMeta?.path ?? "/system/logs",
    meta: indexXCfY1mprIcMeta || {},
    alias: indexXCfY1mprIcMeta?.alias || [],
    redirect: indexXCfY1mprIcMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/logs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pMBjBdRUsMMeta?.name ?? "system-notificationTemplates-id",
    path: _91id_93pMBjBdRUsMMeta?.path ?? "/system/notificationTemplates/:id()",
    meta: _91id_93pMBjBdRUsMMeta || {},
    alias: _91id_93pMBjBdRUsMMeta?.alias || [],
    redirect: _91id_93pMBjBdRUsMMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/notificationTemplates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexIGAWege6eJMeta?.name ?? "system-notificationTemplates",
    path: indexIGAWege6eJMeta?.path ?? "/system/notificationTemplates",
    meta: indexIGAWege6eJMeta || {},
    alias: indexIGAWege6eJMeta?.alias || [],
    redirect: indexIGAWege6eJMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/notificationTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93p7AzuzoOZGMeta?.name ?? "system-organisations-id",
    path: _91id_93p7AzuzoOZGMeta?.path ?? "/system/organisations/:id()",
    meta: _91id_93p7AzuzoOZGMeta || {},
    alias: _91id_93p7AzuzoOZGMeta?.alias || [],
    redirect: _91id_93p7AzuzoOZGMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexs3m2EONyrkMeta?.name ?? "system-organisations",
    path: indexs3m2EONyrkMeta?.path ?? "/system/organisations",
    meta: indexs3m2EONyrkMeta || {},
    alias: indexs3m2EONyrkMeta?.alias || [],
    redirect: indexs3m2EONyrkMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ecKMBZhHalMeta?.name ?? "system-plans-id",
    path: _91id_93ecKMBZhHalMeta?.path ?? "/system/plans/:id()",
    meta: _91id_93ecKMBZhHalMeta || {},
    alias: _91id_93ecKMBZhHalMeta?.alias || [],
    redirect: _91id_93ecKMBZhHalMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/plans/[id].vue").then(m => m.default || m)
  },
  {
    name: indexeq2zNaNdtIMeta?.name ?? "system-plans",
    path: indexeq2zNaNdtIMeta?.path ?? "/system/plans",
    meta: indexeq2zNaNdtIMeta || {},
    alias: indexeq2zNaNdtIMeta?.alias || [],
    redirect: indexeq2zNaNdtIMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/plans/index.vue").then(m => m.default || m)
  },
  {
    name: releaselogfqBGPZigofMeta?.name ?? "system-releaselog",
    path: releaselogfqBGPZigofMeta?.path ?? "/system/releaselog",
    meta: releaselogfqBGPZigofMeta || {},
    alias: releaselogfqBGPZigofMeta?.alias || [],
    redirect: releaselogfqBGPZigofMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/releaselog.vue").then(m => m.default || m)
  },
  {
    name: tools1Y6GaeagjbMeta?.name ?? "system-tools",
    path: tools1Y6GaeagjbMeta?.path ?? "/system/tools",
    meta: tools1Y6GaeagjbMeta || {},
    alias: tools1Y6GaeagjbMeta?.alias || [],
    redirect: tools1Y6GaeagjbMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/tools.vue").then(m => m.default || m)
  },
  {
    name: _91id_93n4mnRuzDI4Meta?.name ?? "system-users-id",
    path: _91id_93n4mnRuzDI4Meta?.path ?? "/system/users/:id()",
    meta: _91id_93n4mnRuzDI4Meta || {},
    alias: _91id_93n4mnRuzDI4Meta?.alias || [],
    redirect: _91id_93n4mnRuzDI4Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVLV14RAg35Meta?.name ?? "system-users",
    path: indexVLV14RAg35Meta?.path ?? "/system/users",
    meta: indexVLV14RAg35Meta || {},
    alias: indexVLV14RAg35Meta?.alias || [],
    redirect: indexVLV14RAg35Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20240820093202/client/pages/system/users/index.vue").then(m => m.default || m)
  }
]